<script setup>
	import { defineProps } from 'vue';
	const props = defineProps({
		infoPanel: Object,
		allotmentId: Number
	})

</script>

<template>
	<div class="c-modal js-modal" :id="'offerid-'+props.allotmentId" aria-modal="false" role="dialog" data-modal-body-open-class="c-modal__body-open--full">
		<div class="c-modal__body js-modal__body">
			<button class="c-modal__close t-link js-modal__close" aria-label="Close"></button>

			<div class="c-modal__boay-wrap">
				<h3>Park up and walk into town.</h3>
				<ul>
					<li v-if="props.infoPanel.minimumNightsRequired !== ''">
						<span class="label">Minimum nights Required: </span>
						<span class="value">{{props.infoPanel.minimumNightsRequired}}</span>
					</li>
					<li v-if="props.infoPanel.minimumNightsRequired !== ''">
						<span class="label">Maximum Guests: </span>
						<span class="value">{{props.infoPanel.maximumGuests}}</span>
					</li>
					<li v-if="props.infoPanel.minimumNightsRequired !== ''">
						<span class="label">Maximum Adults: </span>
						<span class="value">{{props.infoPanel.maximumAdults}}</span>
					</li>
					<li v-if="props.infoPanel.extraAdultRate !== ''">
						<span class="label">Extra Adult Rate: </span>
						<span class="value">{{toNZD(props.infoPanel.extraAdultRate)}}</span>
					</li>
					<li v-if="props.infoPanel.extraChildRate !== ''">
						<span class="label">Extra ChildRate: </span>
						<span class="value">{{toNZD(props.infoPanel.extraChildRate)}}</span>
					</li>
					<li v-if="props.infoPanel.maxGuestsIncludedInPrice !== ''">
						<span class="label">Max guests included in price: </span>
						<span class="value">{{props.infoPanel.maxGuestsIncludedInPrice}}</span>
					</li>
					<li v-if="props.infoPanel.sameDayArrival !== ''">
						<span class="label">Same day arrival </span>
						<span class="value"><span :class="props.infoPanel.sameDayArrival ? 'iconf-tick': 'iconf-close'"></span></span>
					</li>
					<li v-if="props.infoPanel.cancellationsAllowed !== ''">
						<span class="label">Cancellations allowed </span>
						<span class="value"><span :class="props.infoPanel.cancellationsAllowed ? 'iconf-tick': 'iconf-close'"></span></span>
					</li>
					<li v-if="props.infoPanel.changesAllowed !== ''">
						<span class="label">Changes allowed </span>
						<span class="value"><span :class="props.infoPanel.changesAllowed ? 'iconf-tick': 'iconf-close'"></span></span>
					</li>
					<li v-if="props.infoPanel.membershipDiscountApplies !== ''">
						<span class="label">Membership discount applies </span>
						<span class="value"><span :class="props.infoPanel.membershipDiscountApplies ? 'iconf-tick': 'iconf-close'"></span></span>
					</li>
					<li v-if="props.infoPanel.descriptionLong !== ''">
						{{props.infoPanel.descriptionLong}}
					</li>
				</ul>
			</div>

		</div>
		<span class="c-modal__dummy" tabindex="0"><!-- Dummy focusable element --></span>
	</div>
</template>
<script>
	export default {
		name: 'info-modal',
		methods: {
			toNZD(number) {
				return new Intl.NumberFormat("en-NZ", { style: "currency", currency: "NZD" }).format(number)
			}
		}
	};
</script>