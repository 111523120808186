
<template>
	<div v-if="loading" class="c-cart__loader">
		<span class="c-cart__loader-message">Updating cart</span>
		<div class="loader">
			<span></span>
		</div>
	</div>
	<div v-if="cart">
		<cartItemBig v-for="(cartitem, index) in cart" :id="cartitem.id" v-bind:cart="cartitem" :itemIndex="index"></cartItemBig>
		<div class="c-cart__item c-cart__item--membership" v-if="hasMembership">
			<div class="c-cart-top">
				<div class="c-cart__item-copy">
					<span class="c-cart__item-heading">{{membership.Description}}</span>
					<span class="c-cart__details-price">{{toNZD(parseFloat(membership.Amount, 10))}}</span>
				</div>
				<div class="c-cart__item-actions">
					<div class="c-cart__item-actions-delete iconf-trash" @click="removeMembership()"></div>
				</div>
			</div>
		</div>
		<div class="c-cart-totals">
			<div class="c-cart-totals__listing">
				<div class="c-cart-totals__listing-item">
					Extras<span>{{toNZD(getTotalExtra())}}</span>
				</div>
				<div class="c-cart-totals__listing-item" v-if="getTotalPrice('yourDiscountAmount') > 0">
					Discount Applied<span>{{toNZD(getTotalPrice('yourDiscountAmount'))}}</span>
				</div>
				<div class="c-cart-totals__listing-item" v-if="getTotalPrice('potentialDiscountAmount') > 0">
					Members save<span>{{toNZD(getTotalPrice('potentialDiscountAmount'))}}</span>
				</div>

				<div class="c-cart-totals__listing-item c-cart-totals__listing-total">
					Total price<span>{{toNZD(totalPrice())}}</span>
				</div>
			</div>
			<div class="c-cart-totals__actions">
				<a id="AnotherPark" class="c-cart-totals__action-button c-cart-totals__actions-anotherpark" :href="anotherparkurl">{{anotherparktext}}</a>
				<button class="c-cart-totals__action-button c-cart-totals__actions-checkout" :disabled="isDisabled" href="/cart/authenticate/" @click.prevent="cartValidation()">CHECK OUT</button>
			</div>
			<div v-if="isDisabled">
				<p class="error">Some items in your cart are unavailable or have an error. Please select new dates, or a different accommodation type.</p>
			</div>
		</div>
	</div>
	<div class="c-cart-slideout__empty" v-if="cartEmpty">
		<img class="c-cart-slideout__empty-icon" :src="'/content/images/icons/cart-empty.svg'" />
		<div class="c-cart-slideout__empty-text">Your cart is currently empty.</div>
		<a class="c-cart-slideout__empty-book-button js-expand-collapse__toggle" aria-controls="header-booknow" aria-expanded="false">Book Now</a>
	</div>
	<!--T10-251
		<button v-if="!cartEmpty" class="c-cart-clear" @click="clearcart()">
		<span class="iconf-trash"></span>
		Clear Cart
	</button>-->
</template>

<script>
	import moment from 'moment';
	import * as Core from 'Util/core';
	import { CartHelper } from 'Util/cartHelper';
	import cartItemBig from "../cartItemBig/cartItemBig.vue";

	export default {
		name: 'cart',
		components: {
			cartItemBig
		},
		props: [
			"id",
			"anotherparkurl",
			"anotherparktext"
		],
		data() {
			return {
				loading: true,
				isDisabled: false,
				cart: null,
				cartEmpty: false,
				hasMembership: false,
				membership: {},
				total: '',
				extra: '',
			}
		},
		mounted() {

			this.refreshCart();

			window.addEventListener('cart-updated', (event) => {
				this.cart = event.detail.storage;
			});

			if (Core.checkCookie('cart-buyMembership')) {
				this.hasMembership = true;
				this.membership = JSON.parse(decodeURIComponent(Core.getCookie('cart-buyMembership')));
			}
		},
		methods: {

			removeMembership: function () {
				Core.deleteCookie('cart-buyMembership');
				this.hasMembership = false;
				this.refreshCart(true);
			},

			refreshCart: function () {
				let i = 0;
				let cartJson = [];
				let refreshedCart = [];
				let memberEmail = '';
				const storedCart = localStorage.getItem('cart');

				if (storedCart) {
					cartJson = JSON.parse(storedCart);
				}

				if (!storedCart || cartJson.length === 0) {
					this.cartEmpty = true;
					this.loading = false;
					return;
				}

				if (document.getElementById('memberEmail')) {
					memberEmail = document.getElementById('memberEmail').dataset.memberEmail;
				}

				let resStatus = 0
				let resMessage = null;

				cartJson.forEach((cartItem) => {

					const pricingApiURL = `/api/hotels/${cartItem.hotelId}/rooms/${cartItem.roomTypeId}/pricing/${this.format_date(cartItem.date.start, 'YYYY-MM-DD')}/${this.format_date(cartItem.date.end, 'YYYY-MM-DD')}?adults=${cartItem.guest.adult}&children=${cartItem.guest.children}&infants=${cartItem.guest.infant}&promoCode=${cartItem.promoCode}&allotmentId=${cartItem.allotmentId}`;

					fetch(pricingApiURL, {
						method: 'GET'
					})
						.then(res => {
							resStatus = res.status
							resMessage = res.statusText

							switch (resStatus) {
								case 200:
									console.log('success')
									break
								case 400:
									console.log(resMessage)
									break
								case 500:
									console.log('server error, try again')
									break
								default:
									console.log('unhandled')
									break
							}

							return res.json()
						})
						.then((res) => {

							let data = res.offers[0];

							if (data.dailyBreakdown) {
								cartItem.dailyBreakdown = data.dailyBreakdown;
							}

							cartItem.warning = false;
							cartItem.memberEmail = memberEmail;
							cartItem.membersOnly = data.membersOnly;
							cartItem.totalAmount = data.totalAmount;
							cartItem.potentialDiscountAmount = data.potentialDiscountAmount;
							cartItem.yourDiscountAmount = data.yourDiscountAmount;
							cartItem.isAvailable = data.isAvailable;
							cartItem.bookingDetail = data.bookingDetail;

							refreshedCart.push(cartItem);
							console.log(data);

						})
						.catch((error) => {
							console.log(resMessage, error.toString());
							cartItem.warning = true;
							refreshedCart.push(cartItem);
						})
						.finally(() => {
							i++;

							if (i === cartJson.length) {
								refreshedCart.sort((a, b) => {
									const dateA = a.date.start;
									const dateB = b.date.start;

									if (dateA < dateB) {
										return -1;
									}
									if (dateA > dateB) {
										return 1;
									}
									return 0;
								});
								this.cart = refreshedCart;
								this.loading = false;

								CartHelper.saveCart(this.cart);
							}

							if (cartItem.warning === true || cartItem.isAvailable === false) {

								this.isDisabled = true;

							}

						});
				});
			},

			getTotalPrice: function (property) {
				let cartItems = this.cart;
				let sumTotalPrice = cartItems.reduce((accumulator, object) => {
					return accumulator + object[property];
				}, 0);

				return sumTotalPrice;
			},

			getTotalExtra: function () {
				let cartItems = this.cart;
				let extraPriceList = [];

				cartItems.forEach((c) => {
					let chargeableExtras = c.chargeableExtras;
					chargeableExtras.forEach((extra) => {
						extraPriceList.push(extra.bookedTotal)
					})
				});

				let sumTotalExtraPrice = extraPriceList.reduce((accumulator, a) => {
					return accumulator + a;
				}, 0);

				this.extra = sumTotalExtraPrice;

				return sumTotalExtraPrice;
			},

			totalPrice() {
				let cartItems = this.cart;
				let totalChargePerBooking = [];

				cartItems.forEach((c) => {
					totalChargePerBooking.push(c.totalAmount);
					let chargeableExtras = c.chargeableExtras;

					chargeableExtras.forEach((extra) => {
						totalChargePerBooking.push(extra.bookedTotal)
					})
				})

				if (this.hasMembership) {
					totalChargePerBooking.push(this.membership.Amount);
				}

				let totalCharges = totalChargePerBooking.reduce((accumulator, a) => {
					return accumulator + a;
				}, 0);

				return totalCharges;
			},

			toNZD(number) {
				return new Intl.NumberFormat("en-NZ", { style: "currency", currency: "NZD" }).format(number)
			},

			format_date(value, formatType) {
				if (value) {
					return moment(value).format(formatType)
				}
			},

			cartValidation: function () {
				// init variables
				let cartItemForms = document.querySelectorAll(".js-cart-item");
				let isVaild = true;

				cartItemForms.forEach((carItemForm, index) => {
					// init variables
					let requiredFields = carItemForm.querySelectorAll(".js-form-required");
					let cartID = carItemForm.getAttribute('data-cartid');
					let cartItemExpandar = document.querySelector(`.cartItem-${cartID}`);
					let cartItemExpandarBody = cartItemExpandar.querySelector(".js-expand-collapse__item-body");
					let cartItemExpandarBtn = cartItemExpandar.querySelector(".js-expand-collapse__toggle");
					let cartItemSaveBtn = cartItemExpandar.querySelector(".js-cart__save-btn");
					let errorCount = 0;

					requiredFields.forEach((requiredField) => {
						// check if required field value is empty
						if (requiredField.value == null || requiredField.value == "") {
							// add error class
							requiredField.classList.add("error");

							// update error counts
							errorCount++;
							isVaild = false;
						}
					})

					if (errorCount > 0) {
						//  update expender aria expaned to open expend collapse
						cartItemExpandar.setAttribute("aria-expanded", "true");
						cartItemExpandarBody.style.display = 'block';
						cartItemExpandarBtn.setAttribute("aria-expanded", "true");

						// get cart item offset top
						let topPos = cartItemExpandar.offsetTop;

						if (typeof window !== "undefined") {
							// scroll to cart item
							window.scroll(0, topPos);
						}
					} else {
						// save cart item
						cartItemSaveBtn.click();
					}
				})

				if (isVaild) {
					window.location.href = '/cart/authenticate/';
				}
			},
			/* T10-251
			clearcart: function () {

				var cartdata = [];

				if (confirm("Are you sure want to clear your cart?") == true) {
					localStorage.setItem("cart", JSON.stringify(cartdata))
				}

				location.reload();

				removeMembership();
			}
			*/
		}
	};
</script>
