////////////////
// Every page //
////////////////

import $ from 'jquery';

// These modules are always used
import { NavLoad } from 'App/nav/load';
import { Social as SocialShare } from 'App/social/share';
import { Cookie } from 'App/cookie/cookie';
import { BackToTop } from 'App/back-to-top/back-to-top';
import { FadeOnTop } from 'App/fade-on-top/fade-on-top';

NavLoad.init();
SocialShare.init();
Cookie.init();
BackToTop.init();
FadeOnTop.init();

// Publish resize and scroll events for other modules to use
import { publish } from 'Util/pubsub';
import { throttle } from 'Util/throttle';

const $window = $(window);
$window.on('resize', throttle(() => publish('/window/resize'), 250));
$window.on('scroll', () => publish('/window/scroll'));

// These modules are used often enough, or are small enough, that it's worth always loading them
import { expandCollapse } from 'App/expand-collapse/expand-collapse';
import { lightbox } from 'App/lightbox/lightbox';

import { MapControl } from 'App/map/map.control';
import { Rotator } from 'App/rotator/rotator';
import { selectLink } from 'App/select-link/select-link';
import 'tablescroll';
import { VideoGroupLoad } from 'App/video-group/video-group';


import 'Lib/swiper-element-bundle.min';
import 'Lib/number-ticker';
import 'Lib/intellinput.min.js';

import 'Vue/vue-router';

// Expand Collapse
if (document.querySelector('.js-expand-collapse')) {
	expandCollapse.init();
}

// Lightbox
if (document.querySelector('.js-lightbox-single, .js-lightbox-gallery')) {
	lightbox.init();
}

// Maps
var $mapControls = $('.js-map');

if ($mapControls.length) {
	MapControl.init($mapControls);
}

// Rotators
const $rotators = $('.js-rotator');
if ($rotators.length) {
	Rotator.init($rotators);
}

// Select Link
if (document.querySelector('.js-select-link')) {
	selectLink.init();
}

// Table Scroll
// Check DOM for elements requiring JS
const $tables = $('.c-rte').find('table');
if ($tables.length) {
	$tables.tableScroll();
}

// Video Group
if (document.querySelector('.js-video-group')) {
	VideoGroupLoad.init();
}

import { modal } from 'App/modal/modal';
import { PopupPromotion } from 'App/popup/popup-promotion';

modal.init({
	onShow: function ($modal) {
		var $rotators = $modal.find('.js-rotator');
		var thisFlick;
		$rotators.each(function (i, el) {
			thisFlick = $(el).data('flickity-instance');

			if (thisFlick) {
				thisFlick.resize();
			}
		});
	}
});
PopupPromotion.init();

//////////////////////////////////
// Any page, but not every page //
//////////////////////////////////

// Scroll Appear
if (document.querySelector('.js-scroll-appear')) {
	import(/* webpackChunkName: "ScrollAppear" */ 'App/scroll-appear/scroll-appear').then(({ scrollAppear }) => {
		scrollAppear.init();
	});
}

// Umbraco Forms
const $umbracoForms = $('.js-umbraco-form');
if ($umbracoForms.length) {
	import(/* webpackChunkName: "UmbracoForms" */ 'App/form/form.umbraco').then(({ UmbracoForms }) => {
		UmbracoForms.init();
	});
}

// Video Player
if (document.querySelector('.js-video-player')) {
	import(/* webpackChunkName: "VideoPlayer" */ 'App/video/video.player').then(({ VideoPlayer }) => {
		VideoPlayer.init();
	});
}

// Video Gallery
if (document.querySelector('.js-video-gallery')) {
	import(/* webpackChunkName: "VideoGallery" */ 'App/video/gallery').then(({ videoGallery }) => {
		videoGallery.init();
	});
}

// Event calendar add
if (document.querySelector('.js-calendar-link')) {
	import(/* webpackChunkName: "Calendar" */ 'App/event/calendar').then(({ Calendar }) => {
		Calendar.init();
	});
}


if (document.querySelector('.js-footer-nav')) {
	import(/* webpackChunkName: "FooterNav" */ 'App/footer-nav/footer-nav').then(({ FooterNav }) => {
		FooterNav.init();
	});
}

//Tooltip
import(/* webpackChunkName: "Tooltips" */ 'App/tooltip/tooltip').then(({ Tooltip }) => {
	Tooltip.init();
});


//Form Validation 
const formWrapper = document.querySelectorAll(".js-form__wrapper");
if (formWrapper.length) {
	import(/* webpackChunkName: "FormsValidation" */ 'App/form/validation').then(({ FormsValidation }) => {
		FormsValidation.init();
	});
}

// Join CTA expand
$('.js-join-cta__more').click(function(event) {
	event.preventDefault();
	$(this).closest('.js-join-cta').addClass('is-open');
});

// Jump bar stick
if (document.querySelector('.js-jumplink-bar')) {

	if (document.querySelector('.js-jumplink-bar')) {
		var stickyElem = document.querySelector(".js-jumplink-bar");
		var currStickyPos = stickyElem.getBoundingClientRect().top + window.pageYOffset;

		window.onscroll = function () {
			if (window.pageYOffset > currStickyPos) {
				stickyElem.classList.add("stick");
			} else {
				stickyElem.classList.remove("stick");
			}
		}
	}

	var jumplinks = $(".c-park-details__jumplink-bar-link");

	jumplinks.on('click', function (event) {
		event.preventDefault();

		if (window.innerWidth > 1024) {

			var scrollTop = $(window).scrollTop(),
				elementOffset = $(this.hash).offset().top,
				distance = (elementOffset - scrollTop);

			if (distance > 0) {
				$('html,body').animate({
					scrollTop: $(this.hash).offset().top - 100
				}, 500);
			} else {
				$('html,body').animate({
					scrollTop: $(this.hash).offset().top - 180
				}, 500);
			}

		} else {

			$('html,body').animate({
				scrollTop: $(this.hash).offset().top - 150
			}, 500);

		}

	});
}

// Mail Card options
$('#MailCard').submit(function (e) {
	e.preventDefault();

	var form = $(this);
	var actionUrl = form.attr('action');
	var AddressBar = $('#Address');

	if (AddressBar && AddressBar.val()) {

		$.ajax({
			type: "POST",
			url: actionUrl,
			success: function () {
				$(".js-member-card-button").removeClass("done");
				$(".js-member-mail-button").addClass("done");
				$(".js-member-park-message").removeClass("show");
				$(".js-member-mail-message").addClass("show");
			}
		});

	} else {

		$('.c-mailout').click();

	}


});

$('#PostCard').submit(function (e) {
	e.preventDefault();

	var form = $(this);
	var actionUrl = form.attr('action');

	$.ajax({
		type: "POST",
		url: actionUrl,
		success: function () {
			$(".js-member-mail-button").removeClass("done");
			$(".js-member-card-button").addClass("done");
			$(".js-member-mail-message").removeClass("show");
			$(".js-member-park-message").addClass("show");
		}
	});
});

// Checkout
if (document.querySelector('.js-checkout-form')) {
	import(/* webpackChunkName: "Checkout" */ 'App/checkout/checkout').then(({ Checkout }) => {
		Checkout.init();
	});
}

// Mebmers Save
if (document.querySelector('.js-members-save')) {
	import(/* webpackChunkName: "Members Save" */ 'App/checkout/members-save').then(({ MebmersSave }) => {
		MebmersSave.init();
	});
}

// Barcode
if (document.querySelector('.js-barcode')) {
	import(/* webpackChunkName: "Checkout" */ 'App/barcode/barcode').then(({ Barcode }) => {
		Barcode.init();
	});
}

//===========    Accomodation Search

if (document.querySelector('.c-tabbed-booking-control')) {
	$(document).ready(function () {
		// Get the container element
		var btnContainer = document.getElementById("MiniTablist");

		// Get all buttons with class="btn" inside the container
		var btns = btnContainer.getElementsByClassName("c-tabbed-booking__mini-tab");

		// Loop through the buttons and add the active class to the current/clicked button
		for (var i = 0; i < btns.length; i++) {
			btns[i].addEventListener("click", function () {
				var current = document.getElementsByClassName("active");
				current[0].className = current[0].className.replace(" active", "");
				this.className += " active";
			});
		}

		if (window.location.href.indexOf("MemberLogin") > -1) {
			$('#ChangeBookingLink').removeClass('active');
			$('#MemberLoginLink').addClass('active');
			$('#ChangeBooking').attr("aria-expanded", "false");
			$('#MemberLogin').attr("aria-expanded", "true");

			if (location.hash) {
				setTimeout(function () {

					window.scrollTo(0, 0);
				}, 1);
			}
		}
	});
}

//===========    Park finder

$(document).click(function (event) {
	var $target = $(event.target);
	if (!$target.closest('.js-park-finder__filters').length &&
		$('.js-park-finder__filters').is(":visible")) {
		$('.js-park-finder__filters-button').attr('aria-expanded', 'false');
		$('.js-park-finder__filters-dropdown').attr('aria-expanded', 'false');
	}
});

$(document).ready(function () {
	$(".c-park-finder__map-button").click(function () {
		$(".c-park-finder__results-listing-wrapper").toggleClass("Full");
	});
});

//===========    Join Form

if (document.querySelector('.c-join__form')) {

	const phoneInputField = document.querySelector("#JoinMobilePhone");
	const countryData = window.intlTelInputGlobals.getCountryData();

	window.intlTelInput(phoneInputField, {
		hiddenInput: "full_phone",
		utilsScript: "https://cdn.jsdelivr.net/npm/intl-tel-input@18.1.1/build/js/utils.js",
		preferredCountries: ["nz", "au"],
		initialCountry: "nz",
		seperateDialCode: true,
		formatOnDisplay: false,
		autoPlaceholder: "polite",
	});

	function ConvertPhoneNumber() {

		var iti = window.intlTelInputGlobals.getInstance(phoneInputField);
		var fullNumber = iti.getNumber();

		phoneInputField.value = fullNumber;

		//console.log(fullNumber);
	}

	phoneInputField.addEventListener('input', function () {

		ConvertPhoneNumber()

	});

	phoneInputField.addEventListener('load', function () {

		ConvertPhoneNumber()

	});

	function CountrySelect() {
		var country = $(".js-join__country-select").val();
		var address = $(".js-join__address");
		var postit = $("#PostOption");

		if (country == 'New Zealand' || country == 'Australia') {
			address.show();
			postit.show();
		} else {
			address.hide();
			postit.hide();
		}
	}

	$(".js-join__country-select").change(function () {

		CountrySelect();

	});

	function FindOut() {
		var how = $(".js-join__find-out").val();
		var other = $(".c-form__field-group-other");

		//This is the value for other in the select
		if (how == '100000008') {
			other.show();
		} else {
			other.hide();
		}
	}

	$(".js-join__find-out").change(function () {

		FindOut();

	});

	$(window).on('load', function () {

		CountrySelect();
		FindOut();

	});
}

//===========    Membership Details Form

if (document.querySelector('.c-member-profile-form')) {

	function CountrySelect() {
		var country = $("#Country").val();
		var mailbox = $("#MailCard");

		if (country == 'New Zealand' || country == 'Australia') {
			mailbox.show();
		} else {
			mailbox.hide();
		}
	}

	$("#Country").change(function () {

		CountrySelect();

	});

	$(document).ready(function () {
		$(".c-member-profile-form").on("submit", function () {
			$(".c-form__submit-btn").addClass("loading");
			$(".c-form__submit-btn-loader").addClass("loading");
		});
	});

}

if (document.querySelector('.c-checkout__top-form')) {

	$(document).ready(function () {
		$(".js-checkout-form").on("submit", function () {
			$(".c-checkout__button").addClass("loading");
			$(".c-form__submit-btn-loader").addClass("loading");
		});
	});

}

